import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`webdoc Noncommercial EULA`}</h1>
    <p>{`Below is webdoc's noncommercial end-user license agreement and is applicable to all webdoc software and solutions. IMPORTANT — PLEASE READ CAREFULLY — Noncommercial end-user license agreement. BY SELECTING "noncommercial" AT THE TIME OF INSTALLATION, YOU BECOME A PARTY TO THIS AGREEMENT, YOU DECLARE YOU HAVE THE LEGAL CAPACITY TO ENTER INTO THIS AGREEMENT, AND YOU CONSENT TO BE BOUND BY ALL THE TERMS AND CONDITIONS SET FORTH BELOW.`}</p>
    <hr></hr>
    <p>{`This End-User License Agreement ("EULA") is a legal agreement between you, an individual, and Pal Software. This EULA agreement governs your acquisition and use of our webdoc software ("Software") directly from Pal Software or indirectly through a Pal Software authorized reseller or distributor (a "Reseller").
Please read this EULA agreement carefully before completing the installation process and using the webdoc software. It provides a license to use the webdoc software and contains warranty information and liability disclaimers.
If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.
This EULA agreement shall apply only to the Software supplied by Pal Software here with regardless of whether other software is referred to or described herein. The terms also apply to any Pal Software updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.`}</p>
    <h2>{`License Grant`}</h2>
    <p>{`Pal Software hereby grants you a personal, non-transferable, non-exclusive, revocable, non-assignable licence (without the right to sublicense) to use the webdoc software on your devices in accordance with the terms of this EULA agreement.
You are permitted to load the webdoc software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the webdoc software.
You are not permitted to:`}</p>
    <ul>
      <li parentName="ul">{`Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things`}</li>
      <li parentName="ul">{`Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose. `}<strong parentName="li">{`This includes, but is not limited to, generating documentation websites for software that commercially supports your business, directly or indirectly generates revenue, or any software whose source code is not available to the public.`}</strong></li>
      <li parentName="ul">{`Allow any third party to use the Software on behalf of or for the benefit of any third party`}</li>
      <li parentName="ul">{`Use the Software in any way which breaches any applicable local, national or international law`}</li>
      <li parentName="ul">{`Use the Software for any purpose that Pal Software considers is a breach of this EULA agreement`}</li>
    </ul>
    <h2>{`Intellectual Property and Ownership`}</h2>
    <p>{`Pal Software shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Pal Software.
Pal Software reserves the right to grant licences to use the Software to third parties.`}</p>
    <h2>{`Termination`}</h2>
    <p>{`This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to Pal Software.`}</p>
    <p>{`It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate, and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.`}</p>
    <h2>{`Limitations of Warranties and Liabilities`}</h2>
    <p>{`THE SOFTWARE SOLUTION IS PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY WARRANTY ARISING BY LAW, STATUTE, USAGE OF TRADE, OR COURSE OF DEALING. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR THE USE OF THE SOFTWARE AND PAL SOFTWARE DISCLAIMS ALL LIABILITY FOR ANY LOSS, INJURY, DEATH OR DAMAGE RESULTING FROM USE OF THIS PRODUCT, WHETHER DIRECT OR INDIRECT, AND WHETHER OR NOT PAL SOFTWARE HAS BEEN ADVISED OR HAS THE KNOWLEDGE OF THE POSSIBILITY OF SUCH LOSS, INJURY, DEATH OR DAMAGE. IN NOS EVENT SHALL PAL SOFTWARE HAVE ANY LIABILITY TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY COMPENSATORY, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO, LOSS OF REVENUE OR PROFIT, LOSS OF LIFE, LOST OR DAMAGED DATA OR OTHER COMMERCIAL OR ECONOMIC LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, OR THEY ARE FORESEEABLE. WE ARE ALSO NOT RESPONSIBLE FOR CLAIMS BY A THIRD PARTY. THE LIMITATIONS IN THIS SECTION SHALL APPLY WHETHER OR NOT THE ALLEGED BREACH OR DEFAULT IS A BREACH OF A FUNDAMENTAL CONDITION OR TERM OR A FUNDAMENTAL BREACH. Some states do not allow the exclusion of incidental or consequential damages or the limitation of duration of an implied warranty so the above limitations may not apply to you.`}</p>
    <h2>{`Governing Law`}</h2>
    <p>{`This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of the U.S.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      